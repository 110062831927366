
import mixins from 'vue-typed-mixins';
import { mapGetters } from 'vuex';

import WithdrawData from 'Modules/Payment/components/Withdraw/Withdraw.Data.vue';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import SelectFee from 'Control/SelectFee.vue';
import AssetValue from 'UI/AssetValue.vue';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import TextInput from 'Control/TextInput.vue';
import Select from 'Control/Select.vue';
import BankRequisitesResponseData from 'Entities/walletExecutor/BankRequisitesResponseData';

export default mixins(WithdrawData).extend<any, any, any>({
    mixins: [WithdrawData],
    components: {
        Select,
        TextInput,
        SelectFee,
        Icon,
        Button,
        AssetValue,
    },
    data() {
        return {
            feeSize: null,
            previousRoute: '/wallets',
            hasError: false,
            currentRequisite: null,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        isThemeDark() {
            return this.$store.getters.isThemeDark;
        },
        activeAccountId() {
            return this.$store.state.Accounts.activeAccountID;
        },
        activeAccount() {
            return this.$store.state.Accounts.accounts.filter((a) => a.id === this.activeAccountId);
        },
        fees() {
            return this.withdrawUi.fees;
        },
        isWithdrawAvailable() {
            if (!this.fees || !this.fees.low.assetSymbol) {
                return false;
            }
            const balance = this.$store.state.Balances.balances.find(({ assetSymbol, accountId, placementName }) => assetSymbol === this.fees.low.assetSymbol
                && accountId === this.activeAccountId
                && placementName === 'Single Broker');
            if (!balance) {
                return false;
            }
            if (this.fees.low.assetSymbol === this.withdrawUi.asset) {
                if (!this.feeSize) {
                    return true;
                }
                return Number(this.fees[this.feeSize].amount) + Number(this.withdrawUi.amount) <= Number(balance.free);
            }
            if (!this.feeSize) {
                return true;
            }
            return Number(balance.free) >= Number(this.fees[this.feeSize].amount);
        },
        availableBalance() {
            const balance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === this.withdrawUi.asset
                && b.placementName === 'Single Broker'
                && b.accountId === this.activeAccountId);
            return balance ? balance.free : 0;
        },
        feeAssetBalance() {
            const selectedFee = this.fees.low;
            return this.$store.state.Balances.balances.find(({ assetSymbol, accountId, placementName }) => assetSymbol === selectedFee.assetSymbol
                && placementName === 'Single Broker'
                && accountId === this.activeAccountId);
        },
    },
    methods: {
        async performWithdraw() {
            if (!this.isWithdrawAvailable) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Not enough funds');
                return;
            }
            if (this.activeAccount[0].placementLinks && this.activeAccount[0].placementLinks.find((l) => l === 'Single Broker')) {
                await this.doWithdraw(
                    this.activeAccountId,
                    this.previousRoute,
                    {
                        alias: this.withdrawUi.requisite.alias ?? '',
                    },
                );
            } else {
                this.showErrorNotification();
            }
        },
        showErrorNotification() {
            this.$store.dispatch('Notificator/showErrorNotification', 'Your Account Is Still In Progress');
        },
    },
    async mounted() {
        try {
            this.$store.commit(SET_LOADING_ON(undefined));
            const { withdrawUi, previousRoute, requisite } = this.$route.query;
            this.withdrawUi = {
                ...withdrawUi,
                requisite,
            };
            this.currentRequisite = new BankRequisitesResponseData(requisite);
            this.previousRoute = previousRoute;
            await this.getWithdrawFees({
                accountId: this.activeAccountId,
                amount: this.withdrawUi.amount,
            });
        } finally {
            this.$store.commit(SET_LOADING_OFF(undefined));
        }
    },
    watch: {
        async feeSize() {
            let size = 0;
            switch (this.feeSize) {
                case 'high': {
                    size = this.fees.high.amount;
                    break;
                }
                case 'low': {
                    size = this.fees.low.amount;
                    break;
                }
                case 'medium': {
                    size = this.fees.medium.amount;
                    break;
                }
                default: {
                    size = this.fees.low.amount;
                    break;
                }
            }
            await this.SET_FEE_SIZE(size);
        },
    },
});
